import React from 'react'
import Image from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const ProjectCard = ({
                       title,
                       slug,
                       imageData
                     }) => (
    <div className="column is-paddingless">
      <div className="card">
        <div className="card-image">
          <AniLink
              fade
              to={ `/${ slug }` }
          >
            <figure className="image is-relative">
              <Image
                  className="project-image"
                  fluid={ imageData }
                  alt={ title }
              />
              <div className="overlay">
                <p style={ {
                  margin: `auto`
                } }>
                  { title }
                </p>
              </div>
            </figure>
          </AniLink>
        </div>
      </div>
    </div>
);

export default ProjectCard;
