import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import ProjectCard from '../components/ProjectCard'
import Hero from '../components/Hero'

import '../styles/app.scss'

const IndexPage = ({data}) => {
  return (
      <Layout>
        <SEO title="Work" />
        <Hero />

        <section id="work"
                 style={ {
                   minHeight: `unset`,
                 } }>
          <div className="columns"
               style={ {
                 width: `100%`,
                 margin: `0`
               } }>

            { data.projects && data.projects.nodes.length > 0
                ? data.projects.nodes.map(node => (
                    <ProjectCard
                        key={ node.id }
                        title={ node.title }
                        description={ node.description }
                        imageData={ node.thumbnail.localFile.childImageSharp.fluid }
                        slug={ node.slug }
                    />
                ))
                : <div className="container">No projects found.</div>
            }

          </div>
        </section>
      </Layout>
  )
};

export default IndexPage

export const query = graphql`
  query IndexQuery {
    projects: allContentfulProject(sort: {order: ASC, fields: order}) {
      nodes {
        id
        order
        slug
        title
        thumbnail {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600) {
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
                aspectRatio
                base64
              }
            }
          }
        }
      }
    }
  }
`;
