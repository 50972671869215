import React from 'react'
import styled from '@emotion/styled'

const Header = styled.h1`
  font-family: ${ props => props.theme.fonts.body };
  font-weight: 300;
  
  && {
    font-size: 28px;
    line-height: 1.35;
  }
`;

const Hero = () => (
    <section className="intro">
      <Header>
        Nina Yujiri is a Front-end Developer and UI Designer living and working in Montréal, Québec.
      </Header>
    </section>
);

export default Hero;
